/**
 * Header
*/

export default function ($) {
  const dropLink = $('.nav__items > li:last-child a.js-dropdown');
  const winWidth = $(window).width();

  dropLink.on('click touch', function(e) {
    if (winWidth < 1024) {
      const subMenu = $('.nav__items-sub');

      e.preventDefault();

      $(this).toggleClass('active');
      subMenu.toggleClass('open');
      subMenu.slideToggle();
    }
  });

  // Mobile Navigation
  $('.js-hamburger').on('click touch', function () {
    const mobileNav = $('.js-mobile-nav');

    if (!$(this).hasClass('active')) {
      $(this).addClass('active');
      mobileNav.slideDown();
      $('body').css('overflow', 'hidden');
    } else {
      $(this).removeClass('active');
      mobileNav.slideUp();
      $('body').css('overflow', '');
    }
  });
}
