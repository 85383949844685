/**
 * Set all cards to equal height.
 */
export default function($) {
    /**
     * Measure default card heights and increase all to match tallest card in each block.
     */
    function standardizeCardHeights() {

        $('.latest-news__items').each(function() {
            // remove existing fixed height before measuring
            $('.latest-news__item', this).each(function() {
                $(this).css({ 'height': '' });
            });

            // find largest card height
            let largestHeight = 0;
            $('.latest-news__item', this).each(function() {
                const thisCardHeight = $(this).outerHeight();
                if (thisCardHeight > largestHeight) {
                    largestHeight = thisCardHeight;
                }
            });


            // Rounding up the value
            if (!isNaN(largestHeight)) {
                largestHeight = Math.ceil(largestHeight);
            }

            // set fixed height for all cards in block (including 2px border) + buffer
            const standardizedHeight = (largestHeight + 3) + 'px';
            $('.latest-news__item', this).each(function() {
                $(this).css({ 'height': standardizedHeight });
            });
        });
    }

    // set heights on page load
    standardizeCardHeights();

    setTimeout(function() {
        standardizeCardHeights();
    }, 1000);

    // set heights on page resize
    $(window).resize(function() {
        standardizeCardHeights();
    });
}
