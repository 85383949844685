export default function($) {
  if ($('.body-video__video-embed').length === 0)
    return;

  $('.body-video__video-container').each(function () {
    const vimeoContainer = $(this).children('.body-video__video-embed');
    const vimeoContainerId = vimeoContainer.attr('id');
    const vimeoId = vimeoContainer.attr('data-vimeo-id');
    const color = vimeoContainer.attr('data-color');
    const background = vimeoContainer.attr('data-background') == 1;
    const parent = $(this).parents('.body-video');
    const thumbnail = parent.find('.body-video__thumbnail');

    const playButton = $(this).parent().find('.body-video__play-vimeo');
    const playButtonWrapper = $(this).parent().find('.body-video__play-button-wrapper');

    let player = new Vimeo.Player(vimeoContainerId, {
      id: vimeoId,
      color: color,
      responsive: true,
      background: background
    });

    // wait for listeners to bind
    setTimeout(function () {
      $(document).trigger('videoready', {
        'id': vimeoContainerId,
        'player': player
      });

      if (!background)
        playButton.addClass('stopped');
    }, 500);

    playButton.on('click touch', function() {
      player.play();
      vimeoContainer.addClass('playing');
      thumbnail.animate({opacity: 0}, 500, 'swing', function() {
        thumbnail.hide();
      });
      playButtonWrapper.fadeOut();
    });

    player.on('play', function () {
      if (background) {
        playButton.fadeOut();
        vimeoContainer.addClass('playing');
      }
    });
  });
}
