export function smoothAnchorScrollOnPage($) {
  const $html = $("html, body");
  $("a[href^='#'], a[href^='/#']").on("click touch", function (event) {
    let href = $.attr(this, "href");

    // Check if anchor is not in current page
    const anchorPath = href.substr(0, href.indexOf("#") - 1);
    if (anchorPath !== window.location.pathname.substring(1)) {
      return;
    }

    // Get header height
    const headerHeight = $("header.header").outerHeight();

    // Remove leading slash if referencing anchor in the current page
    // Since that breaks wrapping in jquery
    if (href.substring(0, 2) == "/#") {
      href = href.substring(1);
    }

    if (href == "#") {
      return;
    }

    const target = $(href);

    if (target.length <= 0) {
      return;
    }

    event.preventDefault();
    $html.animate(
      {
        scrollTop: target.offset().top - headerHeight,
      },
      500
    );
  });
}
