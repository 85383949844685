export const detectBrowser = ($) => {
  const ua = navigator.userAgent;

  if (/MSIE|Trident/.test(ua)) {
      $('html').addClass('ie');
  } else if (/Edge/.test(ua)) {
      $('html').addClass('edge');
  } else if (/Chrome/.test(ua) && !/Edge/.test(ua)) {
      $('html').addClass('chrome');
  } else if (/Firefox/.test(ua)) {
      $('html').addClass('firefox');
  } else if (/Safari/.test(ua) && !/Chrome/.test(ua)) {
      $('html').addClass('safari');
  }
};
