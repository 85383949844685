export default function ($) {
  if ($('.form-popup-js').length === 0) {
    return
  }

  $('.form-popup-js').magnificPopup({
    type: 'inline',
    midClick: false,
    items: {
      src: '#form__success',
    },
    mainClass: 'mfp-fade',
    fixedContentPos: false,
    closeOnBgClick: true,
    fixedBgPos: true,
    callbacks: {
      beforeOpen: function () {
        this.st.mainClass += ' ' + this.st.el.attr('data-effect')
      },
    },
  })

  $('.form__popup-wrapper__link--js').click(function () {
    $.magnificPopup.close()
  })
}
