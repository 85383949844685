/**
 * Eden Site
 */

/* eslint-env browser */
"use strict";
// Vendor imports
import nav from "./modules/nav";
import slider from "./modules/slider";
import featuredContent from "./modules/featuredContent";
import singleSlider from "./modules/singleSlider";
import popup from "./modules/popup";
import scrollAnimation from "./modules/scrollAnimation";
import bodyVideoYoutube from "./modules/bodyVideoYoutube";
import bodyVideoVimeo from "./modules/bodyVideoVimeo";
import anchorLink from "./modules/anchorLink";
import { detectBrowser } from "./modules/detectBrowser.js";
import { smoothAnchorScrollOnPage } from "./modules/smoothAnchorScrollOnPage.js";
import { smoothAnchorScrollOtherPage } from "./modules/smoothAnchorScrollOtherPage.js";

jQuery.noConflict();

(($) => {
  $(document).ready(() => {
    nav($);
    slider($);
    featuredContent($);
    singleSlider($);
    popup($);
    scrollAnimation($);
    bodyVideoYoutube($);
    bodyVideoVimeo($);
    anchorLink($);
    detectBrowser($);
    smoothAnchorScrollOnPage($);
    smoothAnchorScrollOtherPage($);
  });
})(jQuery);
