/**
 * Slick Slider Function
 */

export default function ($) {
  if ($('.single-slider__wrapper--js').length === 0) {
    return
  }

  $('.single-slider__wrapper--js').slick({
    dots: true,
    arrows: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
  });
}
