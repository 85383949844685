export default function($) {
  if ($('.body-video').length === 0)
    return;

  // load YouTube IFrame API
  const firstScriptTag = document.getElementsByTagName('script')[0];
  const tag = document.createElement('script');
  tag.src = 'https://www.youtube.com/iframe_api';
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  window.onYouTubeIframeAPIReady = function() {
    const players = {};

    $('.body-video__video').each(function() {
        const id = 'yt-' + Math.random().toString(10).substring(2, 6);
      $(this).attr('id', id);

      const ytId = $(this).attr('data-yt');
      players[id] = new YT.Player(id, {
        videoId: ytId,
        playerVars: {
          rel: 0,
          showinfo: 0,
        }
      });
    });
    window.ytPlayers = players;

    $('.body-video__play-youtube').click(function() {
      const parent = $(this).parents('.body-video');
      const id = parent.find('.body-video__video').attr('id');
      const thumbnail = parent.find('.body-video__thumbnail');

      window.ytPlayers[id].playVideo();

      thumbnail.animate({opacity: 0}, 500, 'swing', function() {
        thumbnail.hide();
      });
    });
  }
}
